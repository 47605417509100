<template>
    <div>
        <!-- Main Content -->
        <div class="container mx-auto p-6">
            <h1 class="text-2xl font-bold mb-6 text-center">Investment growth calculator</h1>
            <div class="flex flex-wrap lg:flex-nowrap">
                <form @submit.prevent="calculateGrowthDebounced" class="w-full lg:w-1/3 lg:flex-grow-0 lg:flex-shrink-0 lg:basis-1/3 bg-white p-6 rounded-lg shadow-md mb-6 lg:mb-0">
                    <div class="mb-4">
                        <label for="initialInvestment" class="block text-gray-700">Initial Investment (€):</label>
                        <input type="number" v-model.number="initialInvestment" class="w-full px-3 py-2 border rounded" required />
                    </div>
                    <div class="mb-4">
                        <label for="initialSavings" class="block text-gray-700">Initial Savings (€):</label>
                        <input type="number" v-model.number="initialSavings" class="w-full px-3 py-2 border rounded" required />
                    </div>
                    <div class="mb-4">
                        <label for="monthlyPortfolioContribution" class="block text-gray-700">Monthly Portfolio Contribution (€):</label>
                        <input type="number" v-model.number="monthlyPortfolioContribution" class="w-full px-3 py-2 border rounded" />
                    </div>
                    <div class="mb-4">
                        <label for="monthlySavingsContribution" class="block text-gray-700">Monthly Savings Contribution (€):</label>
                        <input type="number" v-model.number="monthlySavingsContribution" class="w-full px-3 py-2 border rounded" />
                    </div>
                    <div class="mb-4">
                        <label for="years" class="block text-gray-700">Number of Years:</label>
                        <input type="number" v-model.number="years" class="w-full px-3 py-2 border rounded" required />
                    </div>
                    <div class="mb-4">
                        <label for="annualReturn" class="block text-gray-700">Expected Annual Return (%):</label>
                        <input type="number" v-model.number="annualReturn" step="0.01" class="w-full px-3 py-2 border rounded" required />
                    </div>
                    <div class="mb-4">
                        <label for="bankInterest" class="block text-gray-700">Bank Interest Rate (%):</label>
                        <input type="number" v-model.number="bankInterest" step="0.01" class="w-full px-3 py-2 border rounded" required />
                    </div>
                    <button type="submit" class="w-full bg-blue-500 text-white py-2 px-4 rounded">Calculate Growth</button>
                </form>
                <div class="w-full lg:w-2/3 lg:pl-6 lg:flex-grow">
                    <div class="mb-4" v-if="growthData.length > 0">

                        <div class="grid grid-cols-2 gap-4 p-6 bg-gradient-to-r from-blue-50 to-purple-50 rounded-lg shadow-lg text-gray-800">
                            <div class="bg-white p-4 rounded-lg shadow-md hover:shadow-lg transition-shadow duration-300">
                                <p class="text-md font-semibold">Portfolio after {{years}} years:</p>
                                <strong class="text-xl text-blue-600">
                                    {{ formatCurrency(finalInvestmentValue) }}
                                    <span class="text-sm text-green-600 font-bold">(+{{ portfolioGrowth }}%)</span>
                                </strong>
                            </div>
                            <div class="bg-white p-4 rounded-lg shadow-md hover:shadow-lg transition-shadow duration-300">
                                <p class="text-md font-semibold">Bank savings after {{years}} years:</p>
                                <strong class="text-xl text-blue-600">
                                    {{ formatCurrency(finalCashValue) }}
                                    <span class="text-sm text-green-600 font-bold">(+{{ cashGrowth }}%)</span>
                                </strong>
                            </div>
                        </div>
                    </div>
                    <div v-if="chartData.labels.length > 0" class="bg-white p-6 rounded-lg shadow-md mb-6">
                        <h3 class="text-xl font-bold mb-4">Growth Chart</h3>
                        <growth-chart :chart-data="chartData" />
                    </div>
                    <div v-if="growthData.length > 0" class="bg-gradient-to-br from-white to-gray-50 p-8 rounded-xl shadow-lg">
                        <h3 class="text-2xl font-extrabold text-gray-800 mb-6 border-b-2 border-gray-200 pb-2">
                            Monthly Growth
                        </h3>
                        <table class="w-full table-auto border-collapse hidden md:table">
                            <thead>
                            <tr class="bg-gray-200">
                                <th class="px-6 py-4 text-left font-semibold text-gray-700 border-b">Month</th>
                                <th class="px-6 py-4 text-left font-semibold text-gray-700 border-b">Investment Portfolio Value (€)</th>
                                <th class="px-6 py-4 text-left font-semibold text-gray-700 border-b">Bank Cash Value (€)</th>
                            </tr>
                            </thead>
                            <tbody>
                            <template v-for="(data, index) in growthData" :key="index">
                                <tr v-if="data.isYearEnd" class="font-bold bg-indigo-50 hover:bg-indigo-100 transition duration-200">
                                    <td class="px-6 py-4 border-b">Year {{ data.year }} Summary</td>
                                    <td class="px-6 py-4 border-b">{{ formatCurrency(data.totalInvestmentValue) }}</td>
                                    <td class="px-6 py-4 border-b">{{ formatCurrency(data.totalCashValue) }}</td>
                                </tr>
                                <tr v-else class="hover:bg-gray-100 transition duration-200">
                                    <td class="px-6 py-4 border-b">{{ data.month }}</td>
                                    <td class="px-6 py-4 border-b">
                                        <div>{{ formatCurrency(data.investmentValue) }}</div>
                                        <div class="text-sm text-gray-600 mt-1">+ {{ formatCurrency(monthlyPortfolioContribution) }} monthly contribution</div>
                                        <div class="text-sm text-gray-600">+ {{ formatCurrency(data.investmentIncrease) }} interest</div>
                                    </td>
                                    <td class="px-6 py-4 border-b">
                                        <div>{{ formatCurrency(data.cashValue) }}</div>
                                        <div class="text-sm text-gray-600 mt-1">+ {{ formatCurrency(monthlySavingsContribution) }} monthly contribution</div>
                                        <div class="text-sm text-gray-600">+ {{ formatCurrency(data.cashIncrease) }} interest</div>
                                    </td>
                                </tr>
                            </template>
                            </tbody>
                        </table>

                        <!-- Mobile Responsive Version -->
                        <div class="block md:hidden">
                            <template v-for="(data, index) in growthData" :key="index">
                                <div v-if="data.isYearEnd" class="bg-indigo-50 p-4 mb-4 rounded-lg font-bold shadow">
                                    <div class="text-gray-700 mb-2">Year {{ data.year }} Summary</div>
                                    <div class="flex justify-between mb-1">
                                        <span>Investment Portfolio Value (€):</span>
                                        <span>{{ formatCurrency(data.totalInvestmentValue) }}</span>
                                    </div>
                                    <div class="flex justify-between">
                                        <span>Bank Cash Value (€):</span>
                                        <span>{{ formatCurrency(data.totalCashValue) }}</span>
                                    </div>
                                </div>
                                <div v-else class="bg-white p-4 mb-4 rounded-lg shadow hover:bg-gray-50 transition duration-200">
                                    <div class="text-gray-700 mb-2">{{ data.month }}</div>
                                    <div class="flex justify-between mb-1">
                                        <span>Investment Portfolio (€):</span>
                                        <span>{{ formatCurrency(data.investmentValue) }}</span>
                                    </div>
                                    <div class="text-sm text-gray-600 mt-1">+ {{ formatCurrency(monthlyPortfolioContribution) }} monthly contribution</div>
                                    <div class="text-sm text-gray-600">+ {{ formatCurrency(data.investmentIncrease) }} interest</div>

                                    <div class="flex justify-between mt-4 mb-1">
                                        <span>Bank Cash Value (€):</span>
                                        <span>{{ formatCurrency(data.cashValue) }}</span>
                                    </div>
                                    <div class="text-sm text-gray-600 mt-1">+ {{ formatCurrency(monthlySavingsContribution) }} monthly contribution</div>
                                    <div class="text-sm text-gray-600">+ {{ formatCurrency(data.cashIncrease) }} interest</div>
                                </div>
                            </template>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import GrowthChart from './GrowthChart.vue';
import _ from 'lodash';

export default {
    name: 'InvestmentGrowth',
    components: {
        GrowthChart,
    },
    data() {
        return {
            initialInvestment: 0,
            initialSavings: 0,
            finalInvestmentValue: 0,
            finalCashValue: 0,
            monthlyPortfolioContribution: 0,
            monthlySavingsContribution: 0,
            years: 0,
            annualReturn: 0,
            bankInterest: 0,
            growthData: [],
            chartData: {
                labels: [],
                datasets: [
                    {
                        label: 'Investment Portfolio Value',
                        backgroundColor: '#42A5F5',
                        data: [],
                    },
                    {
                        label: 'Bank Cash Value',
                        backgroundColor: '#66BB6A',
                        data: [],
                    },
                ],
            },
            portfolioGrowth: 0,
            cashGrowth: 0,
        };
    },
    mounted() {
        this.loadInputs();
    },
    watch: {
        initialInvestment: 'saveInputsDebounced',
        initialSavings: 'saveInputsDebounced',
        monthlyPortfolioContribution: 'saveInputsDebounced',
        monthlySavingsContribution: 'saveInputsDebounced',
        years: 'saveInputsDebounced',
        annualReturn: 'saveInputsDebounced',
        bankInterest: 'saveInputsDebounced',
    },
    methods: {
        saveInputs() {
            const inputs = {
                initialInvestment: this.initialInvestment,
                initialSavings: this.initialSavings,
                monthlyPortfolioContribution: this.monthlyPortfolioContribution,
                monthlySavingsContribution: this.monthlySavingsContribution,
                years: this.years,
                annualReturn: this.annualReturn,
                bankInterest: this.bankInterest,
            };
            localStorage.setItem('investmentBacktestingInputs', JSON.stringify(inputs));
        },
        saveInputsDebounced: _.debounce(function() {
            this.saveInputs();
        }, 300),
        loadInputs() {
            const savedInputs = localStorage.getItem('investmentBacktestingInputs');
            if (savedInputs) {
                const inputs = JSON.parse(savedInputs);
                this.initialInvestment = inputs.initialInvestment;
                this.initialSavings = inputs.initialSavings;
                this.monthlyPortfolioContribution = inputs.monthlyPortfolioContribution;
                this.monthlySavingsContribution = inputs.monthlySavingsContribution;
                this.years = inputs.years;
                this.annualReturn = inputs.annualReturn;
                this.bankInterest = inputs.bankInterest;
            }
        },
        calculateGrowth() {
            const months = this.years * 12;
            const monthlyReturnRate = (1 + this.annualReturn / 100) ** (1 / 12) - 1;
            const monthlyBankInterestRate = (1 + this.bankInterest / 100) ** (1 / 12) - 1;

            let investmentValue = this.initialInvestment;
            let cashValue = this.initialSavings;

            this.growthData = [];
            this.chartData = {
                labels: [],
                datasets: [
                    {
                        label: 'Investment Portfolio Value',
                        backgroundColor: '#42A5F5',
                        data: [],
                    },
                    {
                        label: 'Bank Cash Value',
                        backgroundColor: '#66BB6A',
                        data: [],
                    },
                ],
            };

            const startDate = new Date();

            for (let i = 0; i < months; i++) {
                const oldInvestmentValue = investmentValue;
                investmentValue *= 1 + monthlyReturnRate;
                const monthlyInvestmentGain = investmentValue - oldInvestmentValue;
                investmentValue += this.monthlyPortfolioContribution;

                const oldCashValue = cashValue;
                cashValue *= 1 + monthlyBankInterestRate;
                const monthlyCashGain = cashValue - oldCashValue;
                cashValue += this.monthlySavingsContribution;

                const currentDate = new Date(startDate);
                currentDate.setMonth(currentDate.getMonth() + i);
                const monthYear = currentDate.toLocaleString('default', { month: 'long', year: 'numeric' });

                this.growthData.push({
                    month: monthYear,
                    investmentValue: investmentValue,
                    investmentIncrease: monthlyInvestmentGain,
                    cashValue: cashValue,
                    cashIncrease: monthlyCashGain
                });

                this.chartData.labels.push(monthYear);
                this.chartData.datasets[0].data.push(investmentValue);
                this.chartData.datasets[1].data.push(cashValue);

                // Check if it's the end of the year or the last month
                if ((currentDate.getMonth() === 11) || (i === months - 1)) {
                    const year = currentDate.getFullYear();
                    this.growthData.push({
                        month: `Year ${year} Summary`,
                        investmentValue: investmentValue,
                        cashValue: cashValue,
                        isYearEnd: true,
                        year: year,
                        totalInvestmentValue: investmentValue,
                        totalCashValue: cashValue,
                    });
                }
            }

            this.calculateGrowthPercentages();
        },
        calculateGrowthDebounced: _.debounce(function() {
            this.calculateGrowth();
        }, 300),
        calculateGrowthPercentages() {
            this.finalInvestmentValue = this.growthData[this.growthData.length - 1].investmentValue;
            this.finalCashValue = this.growthData[this.growthData.length - 1].cashValue;

            this.portfolioGrowth = (((this.finalInvestmentValue - this.initialInvestment) / this.initialInvestment) * 100).toFixed(2);
            this.cashGrowth = (((this.finalCashValue - this.initialSavings) / this.initialSavings) * 100).toFixed(2);
        },
        formatCurrency(value) {
            if(isNaN(value)) {
                return '-';
            }
            return new Intl.NumberFormat('nl-NL', { style: 'currency', currency: 'EUR' }).format(value);
        },
    },
};
</script>

<style scoped>
.investment-backtesting {
    max-width: 1200px;
    margin: 0 auto;
}

@media (min-width: 1024px) {
    .investment-backtesting {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }
}
</style>
