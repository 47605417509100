<template>
    <div class="bg-gray-100 ">
        <!-- Menu -->
        <nav class="bg-gray-800 p-4">
            <div class="container mx-auto flex justify-between items-center">
                <div class="text-white font-bold">Quotez</div>
                <div class="text-base">
                    <router-link to="/" class="text-gray-300 hover:text-white px-3">Investment Growth</router-link>
                    <router-link to="/about" class="text-gray-300 hover:text-white px-3">About</router-link>
                </div>
            </div>
        </nav>

        <!-- Content -->
        <div class="content-around">
            <HomePage v-if="page === 'home'" />
            <InvestmentGrowth v-if="page === 'investment-growth'" />
            <AboutPage v-if="page === 'about'" />
            <TickerDetails v-if="page === 'ticker-details'" />
            <PageNotFound v-if="page === '404'" />

            <PrivacyPage v-if="page === 'privacy'" />
            <TermsPage v-if="page === 'terms'" />
        </div>

        <!-- Footer -->
        <footer class="bg-gray-800 text-white mt-12">
            <div class="max-w-7xl mx-auto px-4 py-12 grid grid-cols-1 md:grid-cols-3 gap-8">
                <div>
                    <h5 class="text-lg font-bold mb-2">About Quotez</h5>
                    <p>Maximize your investment strategies with our advanced backtesting services. At Quotez, we offer comprehensive investment backtesting solutions designed to help you make informed decisions and optimize your portfolio. Explore our services today and take the first step towards smarter investing.</p>
                </div>
            </div>
            <div class="bg-gray-900 py-4">
                <div class="max-w-7xl mx-auto px-4 grid grid-cols-1 md:grid-cols-2 gap-4">
                    <div class="text-center md:text-left">
                        <p class="text-sm">&copy; {{ currentYear }} Quotez. All rights reserved.</p>
                    </div>
                    <div class="text-center md:text-right text-sm">
                        <router-link to="/privacy-statement" class="hover:underline me-4">Privacy Statement</router-link>
                        <router-link to="/terms-of-use" class="hover:underline me-4">Terms of use</router-link>
                    </div>
                </div>
            </div>
        </footer>
    </div>
</template>

<script>
import HomePage from '../components/HomePage.vue';
import InvestmentGrowth from '../components/InvestmentGrowth.vue';
import TickerDetails from '../components/TickerDetails.vue';
import AboutPage from '../components/AboutPage.vue';

import PageNotFound from '../components/PageNotFound.vue';
import PrivacyPage from '../components/base-pages/PrivacyPage.vue';
import TermsPage from '../components/base-pages/TermsPage.vue';

export default {
    name: 'DefaultTemplate',
    components: {
        InvestmentGrowth,
        TickerDetails,
        HomePage,
        AboutPage,
        PageNotFound,
        PrivacyPage,
        TermsPage,
    },
    props: {
        page: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            currentYear: new Date().getFullYear()
        };
    }
};
</script>

<style>
#app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
footer {
    background-color: #1a202c;
    color: #fff;
}
</style>
