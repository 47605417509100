const CACHE_KEY_PREFIX = 'cache_';
const CACHE_EXPIRATION_SUFFIX = '_expiration';

function setCache(key, data, maxAge) {
    const now = new Date().getTime();
    const expiration = now + maxAge;

    localStorage.setItem(CACHE_KEY_PREFIX + key, JSON.stringify(data));
    localStorage.setItem(CACHE_KEY_PREFIX + key + CACHE_EXPIRATION_SUFFIX, expiration);
}

function getCache(key) {
    const now = new Date().getTime();
    const expiration = localStorage.getItem(CACHE_KEY_PREFIX + key + CACHE_EXPIRATION_SUFFIX);

    if (expiration && now > expiration) {
        localStorage.removeItem(CACHE_KEY_PREFIX + key);
        localStorage.removeItem(CACHE_KEY_PREFIX + key + CACHE_EXPIRATION_SUFFIX);
        return null;
    }

    const cachedData = localStorage.getItem(CACHE_KEY_PREFIX + key);
    return cachedData ? JSON.parse(cachedData) : null;
}

export { setCache, getCache };
