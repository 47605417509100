<template>
    <div>
        <div class="container mx-auto p-6 text-center py-40">
            <h1 class="mt-4 text-balance text-5xl font-semibold tracking-tight text-gray-900 sm:text-6xl">This page does not exist</h1>
            <p class="mt-6 text-pretty text-lg font-medium text-gray-500 sm:text-xl/8">Sorry, we couldn’t find the page you’re looking for.</p>

            <div class="mt-10 flex justify-center">
                <router-link to="/" class="text-sm/6 font-semibold text-indigo-600">
                    <span aria-hidden="true">&larr;</span>
                    Back to home
                </router-link>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "PageNotFound"
}
</script>