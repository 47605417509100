<template>
    <div id="app">
        <router-view></router-view>
    </div>
</template>

<script>
import { watch } from 'vue';
import { useRouter } from 'vue-router';

export default {
    setup() {
        const router = useRouter();

        watch(
            () => router.currentRoute.value,
            (to) => {
                if (to.meta.title) {
                    document.title = to.meta.title;
                }
                if (to.meta.description) {
                    let metaDescription = document.querySelector("meta[name='description']");
                    if (metaDescription) {
                        metaDescription.setAttribute("content", to.meta.description);
                    } else {
                        metaDescription = document.createElement('meta');
                        metaDescription.name = 'description';
                        metaDescription.content = to.meta.description;
                        document.head.appendChild(metaDescription);
                    }
                }
            },
            { immediate: true }
        );
    }
};

</script>
