<template>
    <div>
        <div class="py-12">
            <h1 class="text-2xl font-bold mb-6 text-center">Terms and Conditions</h1>

            <div class="max-w-4xl mx-auto px-6 bg-white p-8 rounded-lg shadow-md">
                <h1 class="text-3xl font-semibold mb-6">Terms and Conditions</h1>
                <p class="mb-4">Welcome to Quotez! By using this website, you agree to the following terms and conditions. Please read them carefully.</p>

                <h2 class="text-2xl font-semibold mb-4">1. No Data Storage</h2>
                <p class="mb-6">Quotez does not store any user data. All calculations performed using our tools are executed directly on your computer. We do not collect or retain any information about your usage, ensuring your privacy and security.</p>

                <h2 class="text-2xl font-semibold mb-4">2. User Responsibility</h2>
                <p class="mb-6">You are responsible for the usage of the tools provided by Quotez. All outputs and results are generated on your local machine, and it is your responsibility to interpret and use the results appropriately. Quotez does not give any financial advice.</p>

                <h2 class="text-2xl font-semibold mb-4">3. Disclaimer of Liability</h2>
                <p class="mb-6">Quotez provides the tools as-is, without any warranties or guarantees. Since all calculations are performed locally on your device, we cannot ensure the accuracy or reliability of the results. By using our service, you agree that we are not liable for any direct, indirect, or consequential damages arising from the use of Quotez.</p>

                <h2 class="text-2xl font-semibold mb-4">4. Changes to Terms</h2>
                <p class="mb-6">We reserve the right to update these terms and conditions at any time. Any changes will be effective immediately upon posting to this page. We recommend reviewing these terms regularly to stay informed.</p>

                <h2 class="text-2xl font-semibold mb-4">5. Contact Us</h2>
                <p>If you have any questions or concerns about these terms, please contact us at <a href="mailto:support@quotez.nl" class="text-blue-600 hover:underline">support@quotez.nl</a>.</p>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "TermsPage",
}
</script>