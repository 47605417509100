import { createApp } from 'vue'
import App from './App.vue'
import './tailwind.css'

import { createWebHistory, createRouter } from 'vue-router'
import LayoutView from './layouts/DefaultTemplate.vue'

const routes = [
    // { path: '/', name: 'Home', component: LayoutView, props: { page: 'home' }, meta: { title: 'Portfolio and savings calculator | Quotez', description: 'Calculate and track your savings and investment goals with ease. Quotez\'s Portfolio and Savings Calculator offers tailored insights to help you maximize your returns and achieve financial milestones. Start planning for a secure future today!'} },
    { path: '/', name: 'Investment Growth', component: LayoutView, props: { page: 'investment-growth' }, meta: { title: 'Portfolio and savings growth calculator | Quotez', description: 'Calculate and track your savings and investment goals with ease. Quotez\'s Portfolio and Savings Calculator offers tailored insights to help you maximize your returns. Start planning for a secure future today!'}},
    { path: '/about', name: 'About', component: LayoutView, props: { page: 'about' }, meta: { title: 'About us | Quotez', description: 'Learn more about Quotez, our mission, and the team dedicated to empowering you with tools for smarter financial planning. Discover our commitment to transparency, innovation, and customer-focused solutions that help you achieve your financial goals.'} },
    { path: '/privacy-statement', name: 'Privacy Statement', component: LayoutView, props: { page: 'privacy' }, meta: { title: 'Privacy Statement | Quotez'} },
    { path: '/terms-of-use', name: 'Terms of use', component: LayoutView, props: { page: 'terms' }, meta: { title: 'Terms of use | Quotez'} },
    { path: '/:catchAll(.*)', name: 'Page not found', component: LayoutView, props: { page: '404' }, meta: { title: '404 - Page not found | Quotez', description: 'Calculate and track your savings and investment goals with ease. Quotez\'s Portfolio and Savings Calculator offers tailored insights to help you maximize your returns. Start planning for a secure future today!'}  }
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
})

const app = createApp(App);

app.use(router);
app.mount('#app');