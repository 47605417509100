import axios from 'axios';
import { setCache, getCache } from './cacheUtils';

const api = axios.create({
    baseURL: 'https://www.alphavantage.co', // Set your API base URL here
});

api.interceptors.request.use(async (config) => {
    const cachedResponse = getCache(config.url);

    if (cachedResponse) {
        return Promise.reject({ config, response: cachedResponse, fromCache: true });
    }

    return config;
});

api.interceptors.response.use(
    (response) => {
        const maxAge = 24 * 60 * 60 * 1000; // 24 hours in milliseconds
        setCache(response.config.url, response.data, maxAge);
        return response;
    },
    (error) => {
        if (error.fromCache) {
            return Promise.resolve(error.response);
        }

        return Promise.reject(error);
    }
);

export default api;
