<template>
    <div>

    </div>
</template>

<script>
// import GrowthChart from './GrowthChart.vue';
// import _ from 'lodash';

export default {
    name: 'TickerDetails',
    components: {
    },
    data() {
        return {
            tickers: [],
            years: 0,
        };
    },
    mounted() {
        this.loadInputs();
    },
    created() {
        document.title = 'Ticker details | Quotez';
    },
    methods: {
        loadInputs() {

        }
    },
};
</script>