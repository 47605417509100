<template>
    <div>
        <div class="bg-gray-100 py-12">
            <h1 class="text-2xl font-bold mb-6 text-center">What we do</h1>

            <div class="max-w-4xl mx-auto px-6 bg-white p-8 rounded-lg shadow-md grid grid-cols-1 md:grid-cols-3 gap-8 text-center">
<!--            <div class="max-w-7xl mx-auto px-4 py-12 grid grid-cols-1 md:grid-cols-3 gap-8 text-center">-->
                <!-- Column 1 -->
                <div>
                    <h3 class="text-lg font-semibold mb-4">Investment Strategy Backtesting</h3>
                    <p>We provide tools to backtest your investment strategies using historical data, helping you understand how your ideas would have performed in the past.</p>
                </div>
                <!-- Column 2 -->
                <div>
                    <h3 class="text-lg font-semibold mb-4">Risk Analysis</h3>
                    <p>Our platform allows you to evaluate the risk associated with your investment strategies, giving you a clear picture of potential drawdowns and volatility.</p>
                </div>
                <!-- Column 3 -->
                <div>
                    <h3 class="text-lg font-semibold mb-4">Performance Metrics</h3>
                    <p>We help you analyze key performance metrics, such as returns, Sharpe ratio, and maximum drawdown, to ensure your strategies align with your financial goals.</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "AboutPage.vue",
}
</script>