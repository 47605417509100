<template>
    <div>
        <div class="container mx-auto p-6">
            <h1 class="text-2xl font-bold mb-6 text-center">Privacy statement</h1>


        </div>
    </div>
</template>

<script>
export default {
    name: "PrivacyPage",
}
</script>