<template>
    <div>
        <div class="container mx-auto py-6">
            <section class="max-w-screen-xl mx-2 sm:mx-auto px-4 sm:px-6 lg:px-0 py-6 pb-20 sm:py-8 rounded-[2.25rem] sm:rounded-xl bg-white shadow-lg sm:shadow-md ">
                <div class="w-full flex flex-col lg:flex-row items-center justify-center">
                    <TickerStatistic
                        data-aos="fade-up"
                        title="🔥 Trending"
                        :datasets="trendings"
                        class="xl:border-r border-gray-200 lg:px-8"
                    />
                    <TickerStatistic
                        data-aos="fade-up"
                        data-aos-delay="150"
                        title="🚀 Top Gainers"
                        :datasets="topGainers"
                        class="xl:border-r border-gray-200 lg:px-8"
                    />
                    <TickerStatistic
                        data-aos="fade-up"
                        data-aos-delay="300"
                        title="📉 Top Losers"
                        :datasets="topLosers"
                        class="xl:border-r border-gray-200 lg:px-8"
                    />
                </div>
            </section>
        </div>
        <div class="container mx-auto p-6">
            <div class="bg-white py-24 sm:py-32">
                <div class="mx-auto max-w-7xl px-6 lg:px-8">
                    <h2 class="text-center text-lg font-semibold leading-8 text-gray-900">Trusted by the world’s most innovative teams</h2>
                    <div class="mx-auto mt-10 grid max-w-lg grid-cols-4 items-center gap-x-8 gap-y-10 sm:max-w-xl sm:grid-cols-6 sm:gap-x-10 lg:mx-0 lg:max-w-none lg:grid-cols-5">
                        <img class="col-span-2 max-h-12 w-full object-contain lg:col-span-1" src="https://tailwindui.com/img/logos/158x48/transistor-logo-gray-900.svg" alt="Transistor" width="158" height="48">
                        <img class="col-span-2 max-h-12 w-full object-contain lg:col-span-1" src="https://tailwindui.com/img/logos/158x48/reform-logo-gray-900.svg" alt="Reform" width="158" height="48">
                        <img class="col-span-2 max-h-12 w-full object-contain lg:col-span-1" src="https://tailwindui.com/img/logos/158x48/tuple-logo-gray-900.svg" alt="Tuple" width="158" height="48">
                        <img class="col-span-2 max-h-12 w-full object-contain sm:col-start-2 lg:col-span-1" src="https://tailwindui.com/img/logos/158x48/savvycal-logo-gray-900.svg" alt="SavvyCal" width="158" height="48">
                        <img class="col-span-2 col-start-2 max-h-12 w-full object-contain sm:col-start-auto lg:col-span-1" src="https://tailwindui.com/img/logos/158x48/statamic-logo-gray-900.svg" alt="Statamic" width="158" height="48">
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import TickerStatistic from './TickerStatistic.vue';
import api from './../axiosInstance';

export default {
    name: "HomePage.vue",
    components: {
        TickerStatistic
    },
    data() {
        return {
            trendings: [],
            topGainers: [],
            topLosers: [],
        };
    },
    mounted() {
        this.fetchStockData();
    },
    methods: {
        async fetchStockData() {
            try {
                const result = await api.get('/query?function=TOP_GAINERS_LOSERS&apikey=PNTCEU5DNWMDGC9N');

                if (result.status !== 200 || result.data === null) {
                    return;
                }

                if(typeof result.data !== 'undefined' && typeof result.data.most_actively_traded !== 'undefined') {
                    if (result.data?.most_actively_traded.length > 0) {
                        this.trendings = result.data.most_actively_traded.slice(0, 5);
                    } else {
                        this.trendings = [];
                    }

                    if (result.data?.top_gainers.length > 0) {
                        this.topGainers = result.data.top_gainers.slice(0, 5);
                    } else {
                        this.topGainers = [];
                    }

                    if (result.data?.top_losers.length > 0) {
                        this.topLosers = result.data.top_losers.slice(0, 5);
                    } else {
                        this.topLosers = [];
                    }
                }
            } catch (error) {
                console.error('Error fetching data from API:', error);
            }

            // const result = await axios.get(`https://www.alphavantage.co/query?function=TOP_GAINERS_LOSERS&apikey=PNTCEU5DNWMDGC9N`);
        }
    }
};
</script>